<template>
  <div>
    <loader v-if="!requestIsDoneSuccesfully"></loader>
    <template v-else>
      <div>

        <template v-if="showLikeModal && requestIsDoneSuccesfully">
          <transition name="modalLikes">
            <div @click="showLikeModal = false" class="modalLikes-mask" style="overflow-y: scroll;">
              <div class="modalLikes-container"
                   style="padding: 20px 10px; margin-top:40px;width:250px!important; height: 300px; overflow-y: scroll; " @click.stop>
                <div style="">
                  <div style="z-index: 9997; float: right; ">
                    <img @click="showLikeModal = false" style=" opacity: .6; cursor:pointer;width: 14px; height: 14px;"
                         :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
                  </div>
                  <div style="clear: both; height: 10px;"></div>

                  <div style=" padding: 5px; margin-left: 0; ">
                    <template v-if="loaderLikes">
                      <loader></loader>
                    </template>
                    <template v-else>
                      <div v-for="like in likeModalInfo.likes" style="height: 50px; display: flex; align-items: center;">

                        <div style="flex: 1 ">
                          <router-link :to="'/'+like.username" @click.native="showLikeModal = false;">
                            <img :src="like.avatar_url" style="height: 36px; width: 36px; border-radius: 50%;">
                          </router-link>
                        </div>

                        <router-link :to="'/'+like.username" @click.native="showLikeModal = false;"
                                     style="color: rgb(36, 36, 36);  font-size: 16px; flex:11; width: 250px;margin-left: 8px;">
                          <div style="font-size: 15px; font-weight: 500; color: #2d3640; line-height: 10px;">{{like.username}}</div>
                          <span style="font-size: 11px; color: #5e6b79; font-weight: 500;">{{ like.title }}</span>
                        </router-link>


                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </template>


        <div class="portfolio-modal-body">
          <div class="portfolio-modal-review-main">
            <div class="user-info-div">
              <router-link :to="'/'+receivedUser.username" >
                <img :src="receivedUser.avatar_url" :alt="receivedUser.username"/>
              </router-link>
              <div class="title-and-username">
                <h1 class="title">{{ title }}</h1>
                <p class="sub-title" v-if="ratingUser.id">{{receivedUser.username}}, <router-link :to="'/'+ratingUser.username">{{ratingUser.username}} </router-link> için yaptı.</p>
                <p v-else class="sub-title">{{category_sub_name}}</p>
              </div>
            </div>

            <div style="margin-top: 4px; display: flex; justify-content: flex-end; margin-right: 36px; ">
              <div v-show="likeStatus.action === 1">
                <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(uuid, likeStatus.action)" buttonType="border"  style="display:flex; align-items:center; justify-content:center;color:#8b95a1;width: 98px; height: 40px;font-size: 14px;">
                  <img id="321" src="https://gcdn.bionluk.com/site/cicons/ic-like-empty.svg" onload="SVGInject(this)" class="like-icon"/>
                  <p style="line-height: 1!important; padding-left: 5px">[{{favCount}}]</p>
                </custom-button>
              </div>
              <div v-show="likeStatus.action === -1">
                <custom-button :disabled="buttonLoading" @click="updatePorfolioLikeStatus(uuid, likeStatus.action)"  buttonType="red"  style="display:flex; align-items:center; justify-content:center;color:#fff;width: 98px; height: 40px;font-size: 14px;">
                  <img id="123f" src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon-white"/>
                  <p style="line-height: 1!important; padding-left: 5px">[{{favCount}}]</p>
                </custom-button>
              </div>

              <custom-button :disabled="buttonLoading" @click="sendMessage(receivedUser)" buttonType="dark" style=" margin-left: 20px;width: 128px; height: 38px;font-size: 14px; padding-bottom: 2px;">Mesaj Gönder</custom-button>
            </div>
            <div class="portfolio-modal-big-img">
              <div @click="showIframeByType(selectedItem.file_type, selectedItem.video_url)" style="position: relative;">
                <div v-if="!showIframe && ((selectedItem.video_url && selectedItem.file_type === 'video') || selectedItem.file_type === 'audio')" class="playIcon">
                  <img :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
                </div>

                <div v-if="videoLoader && ((selectedItem.video_url && selectedItem.file_type === 'video') || selectedItem.file_type === 'audio')" class="loader-div">
                  <img :src="selectedItem.cover.image_url">
                  <div>
                    <loader style="height: 30px;width: 30px;"></loader>
                  </div>
                </div>

                <div class="mediaArea">
                  <div v-if="showIframe">
                    <iframe @load="deactivateIframeLoader()" v-show="!videoLoader" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                            width="100%" height="170"
                            frameborder="0" scrolling="no" :src="'https://api.bionluk.com/general/video/' + uuid +'/mobilex' + (screenWidth)+'/'"></iframe>
                  </div>

                  <div v-if="!showIframe">
                    <img class="poster"
                         :style="{width:'100%', height: '170px', 'cursor': (selectedItem.file_type === 'audio' || selectedItem.file_type === 'video') ? 'pointer' : 'default' }"
                         :alt="title"
                         :src="selectedItem.cover.image_url"/>
                  </div>
                </div>
              </div>
            </div>


            <div v-if="items && items.length > 1" class="portfolio-carusel">
              <carousel :perPage="2" :scrollPerPage="true" :navigationEnabled="true" navigationNextLabel="" navigationPrevLabel="" :paginationEnabled="false">
                <slide v-for="(item, index) in items" :key="index" :style="index !== 0 ? 'margin-left: 14px' : ''">
                  <div @click="selectedCarousel = index" :class="selectedCarousel === index ? 'attachment-selected' : 'attachment'">
                    <img @click="selectItem(item)" :id="'carusel-image' + index" class="attachment-img"
                         :alt="title"
                         :src="item.cover.image_url_small"/>
                  </div>
                </slide>
              </carousel>
            </div>


            <div class="stats-area">
              <div @click="likeOrShowModal" style="display: flex">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="red-icon-liked"/>
                <p v-if="favCount" class="number">[{{favCount}}]</p>
                <p class="textt">{{favCount ? 'Beğeni' : 'İlk beğenen sen ol'}}</p>
              </div>
              <div style="display: flex; margin-left: 20px;">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)" class="eye-icon"/>
                <p class="number">{{viewCount}}</p>
                <p class="textt">Görüntülenme</p>
              </div>
            </div>

            <div class="description">{{portfolio.description}}</div>

            <div class="portfolio-share-area" @click="clickedShareButton">
              <social-sharing :url="'https://bionluk.com/freelancer-vitrin/'+uuid" inline-template>
                <div class="user-socialmedia-icons">
                  <network network="facebook" style="cursor: pointer;">
                    <img  style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/facebook.png"/>
                  </network>

                  <network  network="twitter" style="cursor: pointer; margin-left: 30px;">
                    <img style="width: 26px; height: 26px; border-radius: 50%" src="https://gcdn.bionluk.com/site/cicons/png/twitter.png"/>
                  </network>

                  <network network="linkedin" style="cursor: pointer; margin-left: 30px;">
                    <img style="width: 26px; height: 26px; border-radius: 50%"src="https://gcdn.bionluk.com/site/cicons/png/linkedin.png"/>
                  </network>
                </div>
              </social-sharing>
              <div @click="copyToClipboard()" style="margin-left: 30px;">
                <img  class="share-icon" style="width: 26px; height: 26px; border-radius: 50%; cursor: pointer" src="https://gcdn.bionluk.com/site/cicons/png/link.png"/>
              </div>

            </div>


            <div class="sepp"></div>
          </div>




          <div class="portfolio-modal-comment-main">
            <div class="modal-comment-header"><span v-if="commentCount === 0">Bu portfolyoyu beğendin mi? O zaman ilk yorumu sen yap...</span><span v-else>Yorumlar</span></div>
            <div class="modal-comment-body">

              <div  :id="'comment_message'+message.id" v-for="(message, mindex) in comments.messages" v-if="!message.isDeleted">
                <div  class="message-body">
                  <div class="message-user-info">
                    <div style="display: flex;">
                      <router-link :to="'/' + message.user.username" class="message-user-avatar-username">
                        <img class="avatar" :src="message.user.avatar_url"/>
                      </router-link>
                      <div>
                        <div style="display: flex; margin-left: 10px;">
                          <p class="message-username">{{message.user.username}}</p>
                          <div class="rating" v-if="message.rating"><img src="https://gcdn.bionluk.com/site/cicons/ic-star.svg" onload="SVGInject(this)" class="rating-icon"/></div><div class="rating-text" v-if="message.rating">{{message.rating}}</div>
                        </div>
                      </div>
                    </div>
                    <p class="message-created-at">{{message.created_at}} önce</p>
                  </div>

                  <div class="message-like-reply-delete">
                    <p class="message" v-html="message.comment"></p>
                    <div class="message-functions">
                      <div style="display: flex">
                        <template v-if="receivedUser.uuid === user.uuid && !message.reply">
                          <p class="message-function" @click="replyComment(message)">[cevapla]</p>
                          <span style="padding-left: 5px; padding-right: 5px">-</span>
                        </template>
                        <p class="message-function" @click="updateCommentLikeStatus(message)" :style="message.commentLikeStatus ? 'color: #fd4056;' : ''">[{{message.commentLikeStatus ? 'beğeniyi kaldır' : 'beğen'}}]</p>
                        <template v-if="user.username === message.user.username || receivedUser.uuid === user.uuid">
                          <span style="padding-left: 5px; padding-right: 5px">-</span>
                          <p class="message-function" @click="commentDelete(message)">[sil]</p>
                        </template>
                      </div>

                      <div class="message-like-count" v-if="message.commentLikeCount > 0">
                        <div @click="getUserLikes('portfolio_comment', message.id)" style="display: flex" v-show="message.commentLikeStatus">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="comments-like-icon-liked"/>
                          <p class="liked">[{{message.commentLikeCount}}]</p>
                        </div>
                        <div @click="getUserLikes('portfolio_comment', message.id)" style="display: flex" v-show="!message.commentLikeStatus">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="comments-like-icon"/>
                          <p class="not-liked">[{{message.commentLikeCount}}]</p>
                        </div>
                      </div>
                    </div>

                    <div v-if="message.reply">
                      <div style="background-color: rgba(191, 200, 210,0.5);height: 1px;width: 567px;margin-top:25px;"></div>
                      <div class="replied-message">
                        <router-link :to="'/' + receivedUser.username" class="message-user-avatar-username">
                          <img class="avatar-img-replied" :src="receivedUser.avatar_url"/>
                        </router-link>
                        <div style="margin-left: 16px">
                          <p class="comment-username">{{receivedUser.username}}</p>
                          <div class="comment" style="margin-top: 7px;" v-html="`<span style='color:#00a575; font-weight: 600'>@`+message.user.username+`</span> `+message.reply"></div>
                          <div class="message-functions">
                            <p  v-if=" receivedUser.uuid === user.uuid" class="message-function" @click="replyDelete(message, mindex)">[sil]</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <div v-if="mindex === 0 && comments.messages.length > 1" class="go-gig">
                  <img class="avatar-go-gig" :src="receivedUser.avatar_url">
                  <p class="text-go-gig"><span style="font-weight: 600;">{{receivedUser.username}} </span>kullanıcısının işlerini beğendiysen;<br/> <span style="font-size: 14px;color: #eaedf2">iş ilanlarını incelemeni tavsiye ederiz. Belki de ihtiyacın olan o işi birlikte yaparsınız.</span> 😉</p>
                  <router-link :to="'/'+receivedUser.username" class="cuper-white-button" style=" width: 150px; height: 44px; margin-top:20px">İlanlara Göz at</router-link>
                </div>

              </div>
              <div style="width: 100%; margin: 20px auto; ">
                <textarea v-model="newComment" id="comment-input" maxlength="300" class="comment-text-area"></textarea>
                <div style="color: #8b95a1; font-size: 13px; font-weight: 500;display: flex; justify-content: flex-end; margin-top: 10px;">
                  {{newComment.length}} / <span style="color: #2d3640">300</span>
                </div>

                <div style="display: flex; flex-direction: row-reverse; margin-top: 20px;">
                  <button style="width: 160px; height: 54px;" @click="saveComment()" :disabled="buttonLoading || !newComment.length" class="cuper-green-button">Gönder</button>
                </div>

              </div>


            </div>


          </div>



        </div>

      </div>
    </template>
  </div>
</template>

<script>

  import CustomButton from "../../../components/CustomButton";

  export default {
    name: "src-pages-body-showcase-detail-v2_mobile",
    components: {
      CustomButton
    },
    data() {
      return {
        buttonLoading:false,
        commentCount: null,
        description: null,
        favCount: null,
        fileUrl: null,
        title: null,
        receivedUser: {},
        ratingUser:{},
        uuid: null,
        viewCount: null,
        comments: [],
        newComment: '',
        likeStatus: null,
        fileType: null,
        videoURL: null,
        imageURLOriginal: null,
        likes: [],
        showLikeModal: null,
        disableFollowButton: null,
        category_sub_name:'',
        loaderLikes: null,
        items: [],
        disapprovalReasons: [],
        videoLoader: false,
        requestIsDoneSuccesfully: false,
        screenWidth: window.innerWidth,

        portfolio:{},
        selectedCarousel:0,
        selectedItem: 0,

        repliedCommentId:null
      }
    },
    methods: {


      replyDelete(message, mindex){
        this.comments.messages[mindex].reply = null;
        this.api.general.portfolioCommentDelete("portfolio_reply", message.id)
          .then(({data}) => {
            let result = data;
            if (result.success) {

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          });
      },

      replyComment(message){
        this.newComment = '@'+message.user.username + ' ';
        const ele = document.getElementById('comment-input');
        if(message.rating){
          this.repliedCommentId = message.id;
        }
        ele.focus();
      },

      copyToClipboard(){
        const el = document.createElement('textarea');
        el.value = 'https://bionluk.com/freelancer-vitrin/'+this.uuid;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.global.infoToast({description: 'Portfolyo linki kopyalandı.'});
      },

      clickedShareButton(){
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'portfolio share buttons'});
      },

      likeOrShowModal(){
        if(this.favCount){
          this.getUserLikes('portfolio', this.uuid);
        } else {
          this.updatePorfolioLikeStatus(this.uuid, this.likeStatus.action)
        }
      },

      selectItem(item){
        this.selectedItem = item;
      },

      sendMessage(user) {

        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {

          if(this.buttonLoading === false){
            this.buttonLoading = true;
            this.api.workstation.chatOpen(user.username, 0,1)
              .then(({data}) => {
                let result = data;
                if (result.success) {
                  let conversationSendData = {
                    r_username: user.username,
                    r_avatar_url: user.avatar_url,
                    r_id: user.user_id,
                    r_uuid: user.uuid,
                    fromWhere: 'Portfolio Detail',
                    c_uuid: result.data.c_uuid,
                  };
                  this.EventBus.$emit('externalOpenChat', conversationSendData);
                } else {
                  this.$toasted.global.errorToast({description: result.message});
                }
                this.buttonLoading = false;
              })
              .catch(err => {
                this.buttonLoading = false;
                this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
              });
          }
        }

      },
      openWorkstationPortfolioPage() {
        this.$router.push("/panel/portfolyo");
      },
      deactivateIframeLoader() {
        this.videoLoader = false;
      },
      showIframeByType(fileType, videoURL) {
        this.videoLoader = true;
        if (videoURL && (fileType === 'audio' || fileType === 'video')) {
          this.showIframe = true;
        }
      },

      routeShowCase(path) {
        this.$store.state.showCase.from = this.$store.state.route.fullPath;
        this.$store.state.showCase.to = path;
        this.$store.state.showCase.byModal = this.Modals.PORTFOLIO;
        this.$router.push(path);
      },

      getUserLikes(type, uuid) {
        this.showLikeModal = true;
        this.loaderLikes = true;
        this.api.general.getLikedUsers(type, uuid)
          .then(({data}) => {
            let result = data;
            this.loaderLikes = false;
            if (result.success) {
              this.likeModalInfo = {
                favCount: result.data.likeCount,
                likes: result.data.likes
              };
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.showLikeModal = false;
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.showLikeModal = false;
          });
      },

      followAction(like) {
        this.disableFollowButton = true;
        this.api.user.updateFollowStatus(like.u_uuid, like.followStatus.action, this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;
            this.disableFollowButton = false;

            if (result.success) {
              like.followStatus = result.data.followStatus;
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            this.disableFollowButton = false;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      saveComment() {
        this.api.general.savePortfolioComment(this.uuid, this.newComment,this.repliedCommentId, this.$Progress)
          .then(({data}) => {
            let result = data;

            if(result.data && result.data.saveType && result.data.saveType === 'update'){
              let mindex = this.comments.messages.findIndex(m => m.id === this.repliedCommentId);
              this.comments.messages[mindex].reply = result.data.comment;
              this.newComment = '';
              let doc_id = 'comment_message'+this.repliedCommentId;
              document.getElementById(doc_id).scrollIntoView({
                behavior: 'smooth'
              });
            } else {
              result.data.created_at = this.calculateElapsedTime(+new Date() - 1000)
              result.data.isDeleted = false;
              result.data.commentLikeCount = 0;
              result.data.commentLikeStatus = false;
              if(result.data.comment && result.data.comment.substr(0, 1) === '@'){
                let firstWord = result.data.comment.replace(/ .*/,'');
                let withoutFirstWord = result.data.comment.substr(result.data.comment.indexOf(" ") + 1);
                result.data.comment = '<a target="_blank" href="/'+firstWord.substr(1)+'" style="color:#00a575; font-weight: 600">'+firstWord+'</a> '+withoutFirstWord;
              }
              this.comments.messages.push(result.data);
              this.newComment = '';
              this.commentCount += 1;
              this.comments.commentCount = this.commentCount;
              this.EventBus.$emit("modalPortfolioUpdated", {comments: this.comments, favCount: this.favCount, portfolioUuid: this.uuid});
            }
          })
          .catch(err => {
            this.commentCount -= 1;
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      },



      updatePorfolioLikeStatus(uuid, action) {
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.PORTFOLIO, action, uuid,null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              result.data.created_at = this.calculateElapsedTime(+new Date() - 1000)
              this.favCount += action;
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.revertLikeStatus();
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus();
          });
        this.reverseLikeStatus();
      },

      revertLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        } else {
          this.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        }
      },

      reverseLikeStatus() {
        let newLikeIconText = "Beğen";
        let newLikedIconText = "Beğen";
        if (this.likeStatus.action === this.imageUrls.likeIcon.action) {
          this.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        } else {
          this.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        }
      },
      updateCommentLikeStatus(message) {
        let action = message.commentLikeStatus ? -1 : 1;
        this.api.general.portfolioCommentLike("portfolio_comment", action, message.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.revertOrReverseLikeStatus(message);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertOrReverseLikeStatus(message);
          });
        this.revertOrReverseLikeStatus(message);
      },

      revertOrReverseLikeStatus(message) {
        if (message.commentLikeStatus) {
          message.commentLikeCount = message.commentLikeCount - 1;
        }
        else if (!message.commentLikeStatus) {
          message.commentLikeCount = message.commentLikeCount + 1;
        }
        message.commentLikeStatus = !message.commentLikeStatus;
      },

      commentDelete(message) {
        this.api.general.portfolioCommentDelete("portfolio", message.id)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              this.EventBus.$emit("modalPortfolioUpdated", {comments: this.comments, favCount: this.favCount, portfolioUuid: this.uuid});
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.deleteOrUndoMessage(message);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.deleteOrUndoMessage(message);
          });
        this.deleteOrUndoMessage(message);
      },

      deleteOrUndoMessage(message) {
        message.isDeleted = !message.isDeleted;
        if (message.isDeleted) {
          this.commentCount -= 1;
        } else {
          this.commentCount += 1;
        }
        let temp = this.comments;
        this.comments = Object.assign({}, temp);
      },

      getPorfolioData() {
        this.uuid = this.$store.state.routerParams[0];
        this.api.seller.getPortfolio(this.uuid, null, this.$Progress)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.commentCount = result.data.portfolio.comment_count;
              this.favCount = result.data.portfolio.fav_count;
              this.viewCount = result.data.portfolio.viewCount;
              this.description = result.data.portfolio.description;
              this.fileUrl = result.data.portfolio.image_url;
              this.title = result.data.portfolio.name;
              this.receivedUser = result.data.portfolio.user;
              this.ratingUser = result.data.portfolio.rating_user;
              this.comments = result.data.portfolio.comments;
              this.likeStatus = result.data.portfolio.likeStatus;
              this.fileType = result.data.portfolio.file_type;
              this.videoURL = result.data.portfolio.video_url;
              this.imageURLOriginal = result.data.portfolio.image_url_original;
              this.category_sub_name = result.data.portfolio.category_sub_name;
              this.items = this.items.concat(result.data.portfolio.items);
              this.disapprovalReasons = result.data.portfolio.disapproval_reasons;
              this.comments.messages.forEach(comment => {
                if(comment.comment && comment.comment.substr(0, 1) === '@'){
                  let firstWord = comment.comment.replace(/ .*/,'');
                  let withoutFirstWord = comment.comment.substr(comment.comment.indexOf(" ") + 1);
                  comment.comment = '<a target="_blank" href="/'+firstWord.substr(1)+'" style="color:#00a575; font-weight: 600">'+firstWord+'</a> '+withoutFirstWord;
                }
                comment.isDeleted = false;
                comment.created_at = this.calculateElapsedTime(comment.created_at)
              });
              this.likes = result.data.portfolio.likes;
              this.requestIsDoneSuccesfully = true;

              this.portfolio = result.data.portfolio;
              this.selectedItem = result.data.portfolio.items.length > 0 ? result.data.portfolio.items[0] : {};

              this.setFacebookMetaProperties(result.data.meta.facebookMeta);

              this.Helper.trackEvents.pageView(this.Helper.createProjectShowcaseDetailPageProps(result.data.portfolio));

            } else {
              this.requestIsDoneSuccesfully = false;
              this.sendPrerender302(result.data.redirect_url,"302");
              if(!result.data.soft_redirect){
                this.$toasted.global.errorToast({description: result.message});
              }
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.requestIsDoneSuccesfully = false;
          });

      }
    },

    computed:{
      showIframe: function () {
        if ((this.selectedItem.video_url && this.selectedItem.file_type === 'video') || this.selectedItem.file_type === 'audio') {
          return true;
        } else {
          return false;
        }
      }
    },
    created() {
      this.getPorfolioData();
    }
  }

</script>

<style scoped lang="scss">


  .replied-message{
    margin-left: 50px;
    display: flex;
    margin-top: 25px;
    .avatar-img-replied{
      width: 49px;
      height: 49px;
      border-radius: 50%;
      border: 1px solid #eaedf2;
    }

  }



  .comment-text-area{
    text-indent: 20px;
    height: 70px;
    width: 100%;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    background-color: #eff3f8;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #2d3640;
    &:focus{
      opacity: 1;
      background-color: #fff;
    }
  }


  .sepp{
    width: 80%;
    height: 1px;
    opacity: 0.3;
    background-color: #bfc8d2;
    margin: 30px auto 30px;

  }
  .share-icon{
    img{
      width: 26px;
      height: 26px;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .user-socialmedia-icons {
    display: flex;
    flex-direction: row;
    height: 26px;
  }
  .portfolio-share-area{


    margin:30px auto;
    width: 80%;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;


  }

  .description{
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #6a7685;
    text-align: left;
  }

  .stats-area{

    display: flex;
    width: 80%;
    margin:30px auto 20px;

    div{
      &:first-child {
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .number{
      margin-left: 5px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: #2d3640;
    }

    .textt{
      margin-left: 5px;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.5;
      color: #5e6b79;
    }

    .red-icon-liked /deep/ {
      width: 14px;
      height: 14px;
      margin-top: 6px;
      path {
        fill: #fd4056!important;
      }
    }

    .eye-icon /deep/ {
      width: 16px;
      height: 16px;
      margin-top: 5px;
      path {
        fill: #1da1f2!important;
      }
    }
  }

  .cuper-white-button{
    &:hover{
      text-decoration: none!important;
    }
  }

  .go-gig{
    width: 100%;
    height: 300px;
    border-radius: 10px;
    background-image: linear-gradient(100deg, #43c1be 1%, #54aeb8 100%);

    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .avatar-go-gig{
      width: 58px;
      height: 58px;
      border-radius: 50%;
      border:1px solid #ffffff;
    }

    .text-go-gig{
      margin-top: 20px;
      max-width: 85%;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }


  .attachment-selected{

    width: 75px;
    height: 42px;
    border-radius: 10px;
    border: solid 2px #00a575;
    display: flex;
    align-items: center;
    justify-content: center;
    .attachment-img{
      width: 73px;
      border-radius: 7px;
    }
  }


  .attachment{

    width: 75px;
    height: 42px;
    border-radius: 10px;
    border: solid 2px #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    .attachment-img{
      cursor: pointer;
      width: 73px;
      border: solid 1px #eaedf2;
      border-radius: 7px;
    }
  }

  .portfolio-carusel {
    text-align: center;
    width: 100%;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    align-items: center;
    justify-content: center;

  }



  .like-icon /deep/ {
    width: 14px;
    height: 14px;
    margin-top: 4px;
    path {
      fill: #8b95a1!important;
    }
  }

  .like-icon-white /deep/ {
    width: 14px;
    height: 14px;
    margin-top: 4px;
    path {
      fill: #fff!important;
    }
  }

  .gray-button{
    margin-right: 30px;
    width: 120px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px rgba(45, 54, 64, 0.2);
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: center;
    color: #2d3640;
    background: white;
    &:hover{
      color: #fff;
    }
    &:disabled{
      background: white!important;
      color: #2d3640!important;
    }
  }

  .modal-mask {
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .portfolio-modal-close-button {
    position: absolute;
    top: 20px;
    right: 160px;
    z-index: 999799 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }


  .portfolio-modal-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .portfolio-modal-review-main {
  }

  .user-info-div {
    display: flex;
    margin-left: 20px;
    height: 60px;
    align-items: center;
    img {
      width: 58px;
      height: 58px;
      border: 2px solid #bfc8d2;
      border-radius: 50%;
    }
  }

  .title-and-username {
    margin-left: 15px;
    align-self: flex-start;
    .title {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.5px;
      color: #2d3640;
      margin: 0;
    }
    .sub-title{
      margin-top: 5px;
      font-size: 14px;
      font-weight: 300;
      color: #8b95a1;
      a{
        font-size: 14px;
        font-weight: 600;
        color: #fa4a5e;
      }
    }
  }

  .portfolio-modal-big-img {
    width: 100vw;
    height: 170px;
    margin-top: 25px;
    background-color: #ffffff;
  }

  .portfolio-modal-small-img {
    width: 100vw;
    padding-bottom: 28px;

    p {
      font-size: 15px;
      font-weight: 600;
      text-align: left;
      color: #2d3640;
      margin-top: 15px;
      margin-bottom: 12px;
    }

    .attachments-div {
      &:first-child {
        margin-left: 0;
      }
      img {
        height: 73px;
      }
      height: 73px;
      background-color: #ffffff;
      border: solid 1px #bfc8d2;
      cursor: zoom-in;
      display: inline-block;
      margin-left: 4px;
    }
  }

  .loader-div {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      object-fit: cover
    }

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    width: 60px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 60px;
      width: 60px;
    }
  }

  /*comment*/
  .portfolio-modal-comment-main {

    .modal-comment-header {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      margin-left: 20px;
      margin-bottom: 20px;
      font-size: 20px;
      padding-right: 20px;
      font-weight: 500;
      color: #2d3640;
    }
  }

  .modal-comment-body {

    width: 90%;
    margin: 0 auto 80px;
    .message-body {
      margin-bottom: 20px;
      border-radius: 10px;
      box-shadow: 0 0 0 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: rgba(239, 243, 248, 0.5);
      padding: 20px 15px;

      &:last-child {
        border: none;
      }
      border-bottom: solid 1px #dee3e8;
    }
  }


  .rating-text{
    font-size: 14px;
    font-weight: bold;
    padding-left: 2px;
    color: #ffbe00;
  }

  .rating{
    padding-left: 5px;
    .rating-icon /deep/ {
      width: 16px;
      height: 16px;

      path {
        fill: #ffbf00!important;
      }
    }


  }

  .message-user-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .message-created-at {
      font-size: 12px;
      font-weight: normal;
      text-align: right;
      color: #bfc8d2;
    }
    .message-user-avatar-username {
      display: flex;
      text-decoration: none;
      .avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 2px solid #eaedf2;
      }

    }

    .message-username {
      font-size: 16px;
      font-weight: normal;
      color: #4b4f52;
    }
  }

  .message-like-reply-delete {

    margin-top: -15px;
    display: flex;
    flex-direction: column;

    .message {
      margin-left: 50px;
      text-align: left;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.71;
      color: #5e6b79;
      height: auto;
      word-wrap: break-word;
    }
  }


  .message-functions{

    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #5e6b79;
    display: flex;
    justify-content: space-between;
    .message-function{
      cursor: pointer;
      &:hover{
        text-decoration: underline;
        color: #5e6b79;
      }
    }

    .message-like-count{
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.3px;
      text-align: center;

      .not-liked{
        margin-left: 7px;
        color: #8b95a1;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      .liked{
        margin-left: 7px;
        cursor: pointer;
        color: #2d3640;
        &:hover{
          text-decoration: underline;
        }
      }



      .comments-like-icon /deep/ {
        width: 14px;
        height: 14px;
        margin-top: 6px;
        path {
          fill: #8b95a1!important;
        }
      }

      .comments-like-icon-liked /deep/ {
        width: 14px;
        height: 14px;
        margin-top: 6px;
        path {
          fill: #fd4056!important;
        }
      }
    }
  }



  .write-comment-input {
    width: calc(100vw - 160px);
    height: 8px;
    border-radius: 18px;
    background-color: #f4f5f7;
    border: solid 1px #bfc8d2;
    padding: 8px 14px 8px 14px;
    font-size: 15px;
  }

  .send-comment-button {
    position: absolute;
    right: 25px;
    top: 9px;
    padding: 5px;
    font-size: 14px;
    background-color: #26CA9A;
    color: #fff;
    cursor: pointer;
  }

  /*warning*/
  .gigWarning {
    text-align: center;
    height: auto;
    background-color: #FFBF00;

    margin-bottom: 20px;
    margin-top: -20px;

    padding: 20px;
  }

  .warningHeader {
    font-weight: 400;
    font-size: 24px;
    color: #2d3640;
    line-height: 44px;
  }

  .warningText {
    margin-top: 10px;
    font-size: 16px;
    color: #2d3640;
  }

  .bounce-enter-active {
    animation: bounce-in .5s;
  }

  .bounce-leave-to {
    animation: bounce-out .0s;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes bounce-out {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .disapproval_reason {
    height: auto;
    background-color: #FFBF00;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
  }

  .disapproval_reason_inner {
    width: 700px;
    text-align: center;
    margin: 0 auto;
    padding-top: 14px;
    padding-bottom: 24px;
  }

  .warningHeader {
    font-weight: 400;
    font-size: 24px;
    color: #2d3640;
    line-height: 44px;
  }

  .warningText {
    margin-top: 10px;
    font-size: 16px;
    color: #2d3640;
  }

</style>
